import React, { Suspense, useEffect, useState, lazy, Fragment, } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // new import

import Float from "./cubes/miscs/float.js";
import Footer from "./cubes/miscs/footer.js";
import Terms from "./cubes/terms/terms.js";
import { sessioncheck } from "./assets/js/sessioncheck.js";
import logo_anchor from './assets/img/logo_anchor.svg';
import logo_speaker from './assets/img/speaker.svg';
import logo_speaker_off from './assets/img/speaker-off.svg';


const Landing = lazy(() => import("./cubes/landing/landing.js"));
const Main = lazy(() => import("./cubes/main/main.js"));
const Mobile = lazy(() => import("./cubes/miscs/mobile.js"));
const E404 = lazy(() => import("./cubes/miscs/e404.js"));
const Play = lazy(() => import("./cubes/play/play.js"));
const Chat = lazy(() => import("./cubes/app_chat/chat.js"));
const Backend2 = lazy(() => import("./cubes/app_navalwarfare_ingame/ig_backend.js"));
const Backend1 = lazy(() => import("./cubes/app_navalwarfare/navalwarfare.js"));
const Scoreboard = lazy(() => import("./cubes/scoreboard/scoreboard.js"));

export default function App() {
    const [Orientation, setOrientation] = useState(window.matchMedia("(orientation: portrait)").matches);
    const [TOS, setTOS] = useState(false);
    const [Sound, setSound] = useState(localStorage.getItem("sound"));

    // Sound option, default is True
    if (localStorage.getItem("sound") === null) {
        localStorage.setItem("sound", "on");
        setSound("on");
    } else {
        if (localStorage.getItem("sound") === "on" && Sound === false) {
            setSound("on");
        } else if (localStorage.getItem("sound") === "off" && Sound === true) {
            setSound("off");
        }
    }
    const changeSound = () => {
        if (Sound === "off") {
            localStorage.setItem("sound", "on");
            setSound("on");
        } else if (Sound === "on") {
            localStorage.setItem("sound", "off");
            setSound("off");
        }
    }


    // ########## THIS PART BE REPLACE WITH MIN-WIDTH and MIN-HEIGHT CONDITIONS
    // Verify if its mobile or not
    const handleOrientation = () => {
        setOrientation(window.matchMedia("(orientation: portrait)").matches)
    }
    useEffect(() => {
        window.addEventListener("resize", handleOrientation);
    }, []);

    // Verify if its in Landscape mode or not
    const [IsLandscape, setIsLandscape] = useState(false);
    useEffect(() => {
        window.screen.width < 1000 ? setIsLandscape(true) : setIsLandscape(false);
    }, []);

    function detectWindowSize() {
        window.screen.width < 1000 ? setIsLandscape(true) : setIsLandscape(false);
    }
    window.onresize = detectWindowSize;

    // ########## CHECK IF SESSION KEY EXISTS
    useEffect(() => {
        const session_check = async () => {
            const outdata = await sessioncheck();
            const pathname = window.location.pathname
            // if the terms isn't accepted and user is not in the landing page, redirect to landing page
            if (outdata['code'] !== 'session_key_terms_accepted' && pathname !== '/') {
                window.location = '/'
            }
        }
        session_check();
    }, []);


    return (
        < BrowserRouter >
            <img src={logo_anchor} className="app_img" alt="logo" />

            {Sound === "on"
                ?
                <img src={logo_speaker} className="app_img_sound" alt="logo" onClick={changeSound} />
                :
                <img src={logo_speaker_off} className="app_img_sound" alt="logo" onClick={changeSound} />
            }

            <Suspense fallback={<div></div>}>

                {Orientation ? <Mobile /> : <Fragment />}

                <Routes>
                    <Route path="/" element={<Landing setTOS={setTOS} />} />
                    <Route path="/main" element={<Main IsLandscape={IsLandscape} />} />
                    <Route path="/play" element={<Play IsLandscape={IsLandscape} />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/test1" element={<Backend1 />} />
                    <Route path="/test2" element={<Backend2 />} />
                    <Route path="/score" element={<Scoreboard />} />
                    <Route path="*" element={<E404 />} />
                </Routes>

            </Suspense>
            <Float />

            <Footer setTOS={setTOS} />
            {TOS ? <Terms setTOS={setTOS} /> : <Fragment />}

        </BrowserRouter >
    )
} 