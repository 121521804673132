import React from 'react';
import './footer.scss';


const current = new Date();
const date = `${current.getFullYear()}`;

function Copyright({ setTOS }) {

    function open_terms() {
        setTOS(true);
    }

    return (
        < footer className='flexcol' >
            <div className='copyright'>
                <div className='terms' onClick={open_terms}>Terms of service</div>
                Copyright © {date} Naval Domination
            </div>
        </footer >
    )
}

export default Copyright;
